import React, { useState } from 'react';
import Layout from '../../../components/Layout';
import Seo from '../../../components/Seo';
import HeaderBar from '../../../components/courses/HeaderBar';
import Select from '../../../components/fields/Select';
import isEmpty from '../../../utils/isEmpty';
import withTranslations from '../../../components/hoc/withTranslations';
import { navigate } from 'gatsby';
import { loadStripe } from '@stripe/stripe-js';
import stripePk from '../../../utils/stripePk';

const SignUp = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [university, setUniversity] = useState('');
  const [number, setNumber] = useState('');
  const [reason, setReason] = useState('');
  const [medium, setMedium] = useState('Facebook');
  const [errorMessage, setErrorMessage] = useState(null);
  const [loading, setLoading] = useState(false);

  const onContinue = async () => {
    if (isEmpty(name) || isEmpty(email) || isEmpty(number)) {
      setErrorMessage('Te faltó información importante. Verifica e intenta de nuevo.');
      return;
    }

    setLoading(true);
    try {
      await fetch('https://hooks.zapier.com/hooks/catch/4630101/oa8knmc/', {
        method: 'POST',
        body: JSON.stringify({
          name,
          email,
          university,
          number,
          reason,
          medium
        }),
        headers: {
          Accept: 'application/json'
        }
      });
    } catch (error) {
      console.log(error);
      setLoading(false);
      return;
    }

    const stripe = await loadStripe(stripePk);
    stripe
      .redirectToCheckout({
        items: [{ sku: 'sku_HxS6jNaA4N1lUG', quantity: 1 }],
        successUrl: 'https://positive0.com/courses/react/success',
        cancelUrl: 'https://positive0.com/courses/react',
        customerEmail: email
      })
      .then(async function(result) {
        if (result.error) {
          var displayError = document.getElementById('error-message');
          displayError.textContent = result.error.message;
        }
      });
  };

  return (
    <Layout manualMargin manualPadding>
      <Seo title='Registro de Webapps modernas con React' keywords={['web', `workshop`, `taller`, `positive zero`]} />
      <div className='content max-w-4xl px-4 mx-auto'>
        <h1>Inscripción - Webapps modernas con React</h1>

        {/* <h2>Se han agotado los lugares</h2>
        <p>Gracias por tu interés en el taller. Mántente alerta para futuras ediciones de este evento.</p> */}

        <section>
          <HeaderBar>Datos generales</HeaderBar>
          <div className='input-container flex flex-col'>
            <input
              value={name}
              onChange={e => setName(e.target.value)}
              name='name'
              type='text'
              className='global-input'
              placeholder='Nombre completo'
            />

            <input
              value={email}
              onChange={e => setEmail(e.target.value)}
              name='email'
              type='email'
              className='global-input'
              placeholder='Correo electrónico'
            />

            <input
              value={number}
              onChange={e => setNumber(e.target.value)}
              name='number'
              type='text'
              className='global-input'
              placeholder='Número de teléfono'
            />

            <input
              value={university}
              onChange={e => setUniversity(e.target.value)}
              name={'university'}
              type='text'
              className='global-input'
              placeholder='Universidad (opcional)'
            />

            <Select
              label='¿Cómo te enteraste de este curso?'
              options={['Facebook', 'Amigos', 'Página web', 'Instagram', 'Otro']}
              value={medium}
              onChange={value => setMedium(value)}
            />

            <Select
              label='¿Por qué quieres tomar este curso?'
              options={['Curiosidad', 'Desarrollo profesional', 'Reforzar conocimiento', 'Mejorar currículum', 'Otra']}
              value={reason}
              onChange={value => setReason(value)}
            />
          </div>
        </section>

        <p>El costo es de $389.00 MXN</p>

        <p className='error-message'>{errorMessage}</p>

        <div className='button-container'>
          <button disabled={loading} onClick={onContinue}>
            Proceder al pago
          </button>
        </div>
      </div>

      <style jsx>{`
        .content {
          margin-bottom: 200px;
        }

        .input-container {
          max-width: 500px;
        }

        input {
          margin-bottom: 30px;
        }

        .payment-methods-container {
          display: flex;
          flex-wrap: wrap;
        }

        .payment-method-container {
          display: flex;
          flex-direction: column;
          align-items: center;
          margin: 10px;
        }

        .pay-date {
          color: gray;
        }

        .payment-method {
          cursor: pointer;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          padding: 15px;
          border: 3px solid gray;
          border-radius: 8px;
          margin: 15px;
          user-select: none;
        }

        .workshops-container {
          display: flex;
          align-items: center;
          flex-wrap: wrap;
          margin-bottom: 20px;
        }

        .workshop {
          cursor: pointer;
          display: flex;
          align-items: center;
          padding: 8px 15px;
          border: 3px solid gray;
          border-radius: 8px;
          margin-top: 15px;
          margin-right: 15px;
          user-select: none;
        }

        .accent {
          border-color: #ffa500;
        }

        .gray {
          border-color: gray;
        }

        .icon-label {
          margin-top: 10px;
        }

        .button-container {
          margin-top: 50px;
        }

        .check-container {
          margin-right: 10px;
        }

        .error-message {
          color: red;
        }
      `}</style>
    </Layout>
  );
};

export default withTranslations()(SignUp);
